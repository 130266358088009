<!-- 平台设置 -->
<template>
  <div class="paltform-setting">
    <!-- container -->
    <div class="container">
      <!-- tabs -->
      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick" class="settingTabs">
          <el-tab-pane label="平台设置" name="plat">
            <!-- 平台设置 -->
            <div class="form">
              <el-form ref="platForm" :model="platForm" label-width="110px" :rules="rules">
                <el-form-item label="商城名称:" prop="name">
                  <el-input v-model="platForm['name']" placeholder="请输入商场名称"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="contact_number">
                  <el-input v-model="platForm['contact_number']" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="热线时间:" prop="hot_line_time">
                  <el-time-picker is-range v-model="platForm['hot_line_time']" range-separator="至" format="HH:mm"
                    value-format="HH:mm" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                  </el-time-picker>
                </el-form-item>
                <!-- <el-form-item label="展示样式:">
                  <div class="tab-content">
                    <div class="tab_wrap">
                      <div
                        class="tab-item"
                        :class="[
                          platForm['show_style_type'] == 0 ? 'active' : '',
                        ]"
                        @click="typeTab(0)"
                      ></div>
                      <div
                        class="tab-item"
                        :class="[
                          platForm['show_style_type'] == 1 ? 'active' : '',
                        ]"
                        @click="typeTab(1)"
                      ></div>
                    </div>
                  </div> -->
                <!-- <div class="template" @click="handleClick($event)" ref="template">
                                        <a href="javascript:void(0)"><img src="~@/assets/images/temp-1.svg"></a>
                                        <a href="javascript:void(0)"><img src="~@/assets/images/temp-2.svg"></a>
                                                                                                                                                                                                                                                                                                                                                                </div> -->
                <!-- </el-form-item> -->
                <!-- <el-form-item label="选择配色方案:">
                                    <div class="template">
                                        <el-radio-group v-model="platForm['color_matching']">
                                            <el-radio v-for="item in colorTextList" :label="item" border>{{
                                                item.text }}</el-radio>
                                        </el-radio-group>
                                    </div>
                                </el-form-item> -->
                <el-form-item label="Logo:">
                  <div class="addressright-logo">
                    <div class="addresstx" @click="handleImageClick('logo_url', 'platForm')">
                      <i class="el-icon-plus" v-show="!platForm['logo_url']"></i>
                      <img class="addresstximage" :src="platForm.logo_url" v-show="!!platForm['logo_url']" />
                    </div>
                    <span>建议尺寸：200*100像素</span>
                  </div>
                </el-form-item>
                <el-form-item label="公司名称:">
                  <el-input v-model="platForm['company_name']" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="公司网址:">
                  <el-input v-model="platForm['company_url']" placeholder="请输入公司网址"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="后台显示设置" name="back">
            <!-- 后台显示设置 -->
            <div class="backstage">
              <el-form>
                <!-- logo-setting -->
                <div class="logo-setting">
                  <div class="images">
                    <img src="~@/assets/images/icon_setting.svg" alt="" />
                    <div class="upload">
                      <el-form-item class="clearfloat" label="浏览器icon：" prop="icon_url">
                        <div class="addressright">
                          <div class="addresstx" @click="
                            handleImageClick('icon_url', 'paltBackInfo')
                            ">
                            <i class="el-icon-plus" v-show="!paltBackInfo['icon_url']"></i>
                            <img class="addresstximage" :src="paltBackInfo.icon_url"
                              v-show="!!paltBackInfo['icon_url']" />
                            <span>建议尺寸：100*100像素</span>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item class="clearfloat" label="后台logo：" prop="backend_logo_url">
                        <div class="addressright">
                          <div class="addresstx" @click="
                            handleImageClick(
                              'backend_logo_url',
                              'paltBackInfo'
                            )
                            ">
                            <i class="el-icon-plus" v-show="!paltBackInfo['backend_logo_url']"></i>
                            <img class="addresstximage" :src="paltBackInfo.backend_logo_url"
                              v-show="!!paltBackInfo.backend_logo_url" />
                            <span>建议尺寸：36*36像素</span>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="login-info">
                  <div class="images">
                    <img src="~@/assets/images/login-info.svg" alt="" />
                    <div class="upload">
                      <el-form-item class="clearfloat" label="前景图片：" prop="prospect_url">
                        <div class="addressright">
                          <div class="addresstx" @click="
                            handleImageClick('prospect_url', 'paltBackInfo')
                            ">
                            <i class="el-icon-plus" v-show="!paltBackInfo.prospect_url"></i>
                            <img class="addresstximage" :src="paltBackInfo.prospect_url"
                              v-show="!!paltBackInfo.prospect_url" />
                            <span>建议尺寸：800*1040像素</span>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item class="clearfloat" label="登录logo：" prop="login_logo_url">
                        <div class="addressright">
                          <div class="addresstx" @click="
                            handleImageClick('login_logo_url', 'paltBackInfo')
                            ">
                            <i class="el-icon-plus" v-show="!paltBackInfo.login_logo_url"></i>
                            <img class="addresstximage" :src="paltBackInfo.login_logo_url"
                              v-show="!!paltBackInfo.login_logo_url" />
                            <span>建议尺寸：256*64像素</span>
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item class="clearfloat" label="版本信息：" prop="copyright_info">
                        <el-input v-model="paltBackInfo.copyright_info" class="diy--big"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="login-setting">
                  <div class="images">
                    <img src="~@/assets/images/login-setting.svg" alt="" />
                    <div class="upload">
                      <el-form-item class="clearfloat" label="背景图片：" prop="background_url">
                        <div class="addressright">
                          <div class="addresstx" @click="handleImageClick('background_url')">
                            <i class="el-icon-plus" v-show="!paltBackInfo.background_url"></i>
                            <img class="addresstximage" :src="paltBackInfo.background_url"
                              v-show="!!paltBackInfo.background_url" />
                            <span>建议尺寸：1920*1080像素</span>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="客服设置" name="service">
            <div class="form">
              <el-form :model="serviceModel" label-width="110px">
                <div class="customer_title">企业微信客服（PC）</div>
                <el-form-item label="地址链接:">
                  <el-input v-model="serviceModel.microapp_customer_url" placeholder="请输入pc端企业微信客服链接"></el-input>
                </el-form-item>
                <el-form-item label="二维码:">
                  <div class="addressright">
                    <div class="addresstx" @click="
                      handleImageClick(
                        'microapp_customer_logo',
                        'serviceModel'
                      )
                      ">
                      <i class="el-icon-plus" v-show="!serviceModel['microapp_customer_logo']"></i>
                      <img class="addresstximage" :src="serviceModel.microapp_customer_logo"
                        v-show="!!serviceModel['microapp_customer_logo']" />
                    </div>
                  </div>
                </el-form-item>
                <div class="customer_title" v-if="false">小程序客服（小程序）</div>
                <el-form-item label="地址链接:" v-if="false">
                  <el-input v-model="serviceModel.pc_customer_url" placeholder="请输入小程序客服客服链接"></el-input>
                </el-form-item>
                <el-form-item label="二维码:" v-if="false">
                  <div class="addressright">
                    <div class="addresstx" @click="
                      handleImageClick('pc_customer_logo', 'serviceModel')
                      ">
                      <i class="el-icon-plus" v-show="!serviceModel['pc_customer_logo']"></i>
                      <img class="addresstximage" :src="serviceModel.pc_customer_logo"
                        v-show="!!serviceModel['pc_customer_logo']" />
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="客户端设置" name="client">
            <div class="form">
              <el-form :model="clientForm" label-width="220px">
                <el-form-item label="微信PC应用APP ID:">

                  <div class="wechat_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="clientForm.pc_app_id"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="微信PC应用APP Secret:">
                  <div class="wechat_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="clientForm.pc_app_secret"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="微信小程序应用APP ID:">
                  <div class="wechat_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="clientForm.microapp_app_id"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="微信小程序应用APP Secret:">
                  <div class="wechat_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="clientForm.microapp_app_secret"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="微信小程序应用APP原始ID:">
                  <el-input v-model="clientForm.microapp_original_id"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="中台设置" name="middleground">
            <div class="form">
              <el-form :model="middleForm" label-width="220px">
                <el-form-item label="中台AppId：">
                  <div class="more_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="middleForm.platform_app_id" placeholder="请输入中台appid"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="中台AppKey：">
                  <div class="more_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="middleForm.platform_app_key" placeholder="请输入中台appkey"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="中台AppUrl：">
                  <div class="more_input">
                    <div class="more_hide" @click.stop="hideMore($event)">
                      已隐藏内容，点击查看或编辑
                    </div>
                    <el-input v-model="middleForm.platform_app_url" placeholder="请输入中台appUrl"></el-input>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="协议设置" name="about">
            <div class="form">
              <el-form :model="aboutForm" label-width="160px">
                <el-form-item label="隐私政策：">
                  <Editor v-model="aboutForm.about_privacy" placeholder="请输入隐私政策" height="500"
                    @uploadImgs="uploadImgs('about_privacy', 'editor')" />
                </el-form-item>
                <el-form-item label="用户协议：">
                  <Editor v-model="aboutForm.about_user" placeholder="用户协议" height="500"
                    @uploadImgs="uploadImgs('about_user', 'editor')" />
                </el-form-item>
                <el-form-item label="注意事项：">
                  <Editor v-model="aboutForm.announcements" placeholder="注意事项" height="500"
                    @uploadImgs="uploadImgs('announcements', 'editor')" />
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- footer -->
    <diy-footer-button v-if="hasPerm(['setting.trd_service.mail.update'])">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </diy-footer-button>
    <!-- 图片 -->
    <pictureSelect ref="imglist" :multipled="is_multiple" @change="imgbrand($event)"></pictureSelect>
  </div>
</template>

<script>
import { Message } from "element-ui";

const colorTextList = [
  {
    color: "linear-gradient(360deg, #3444FF 0%, #030F9F 100%);",
    text: "渐变蓝",
  },
  {
    color: "linear-gradient(360deg, #D15EE7 0%, #660EFF 100%);",
    text: "高雅紫",
  },
  {
    color: "linear-gradient(360deg, #FE8C90 0%, #FC3B45 100%);",
    text: "芭比粉",
  },
  {
    color: "linear-gradient(360deg, #00D294 0%, #009167 100%);",
    text: "健康绿",
  },
  {
    color: "#28272D;",
    text: "沉稳黑",
  },
  {
    color: "#3B69FF;",
    text: "天空蓝",
  },
  {
    color: "#FF5654;",
    text: "火焰红",
  },
];
export default {
  data() {
    return {
      activeName: "plat",
      platForm: {
        name: "", //名称
        contact_number: "", //电话
        hot_line_time: "", //热线时间
        color_matching: colorTextList[0], //颜色
        // 'show_style_type': 0,
        logo_url: "", //logo
        company_name: "", //公司名称
        company_url: "", //公司网址
      },
      // tabIndex: 0,
      colorTextList: [],
      rules: {
        ["name"]: [
          { required: true, message: "请输入商城名称!", trigger: ["blur"] },
        ],
        ["contact_number"]: [
          {
            required: true,
            validator: this.contarctNumValid,
            trigger: ["blur"],
          },
        ],
        ["hot_line_time"]: [
          {
            required: true,
            message: "请选择热线时间!",
            trigger: ["change", "blur"],
          },
        ],
      },

      // 后台设置
      paltBackInfo: {
        backend_logo_url: "", //平台后台logo
        background_url: "", //平台 后台背景图片地址
        copyright_info: "", //平台 后台版权信息
        icon_url: "", //平台 后台icon地址
        login_logo_url: "", //平台 后台登录logo
        prospect_url: "", //平台前景图片
      },
      imageType: "", //标识点击哪个上传
      is_multiple: 0, //图片单选还是多选 0：单选 1：多选
      setSoure: "",
      serviceModel: {
        microapp_customer_logo: "",
        microapp_customer_url: "",
        pc_customer_logo: "",
        pc_customer_url: "",
      },
      clientForm: {
        microapp_app_id: "",
        microapp_app_secret: "",
        microapp_original_id: "",
        pc_app_id: "",
        pc_app_secret: "",
      },
      // 协议设置
      aboutForm: {
        about_privacy: "",
        about_user: "",
        announcements: '', //注意事项
      },
      middleForm: {
        platform_app_id: "",
        platform_app_key: "",
        platform_app_url: "",
      },  //中台设置
    };
  },
  created() {
    this.$set(this, "colorTextList", colorTextList);
    this.getPlatSettingInfo();
    this.getBackSettingInfo();
    this.platServiceApi();
    this.platClientApi();
    this.middleApi()
    this.aboutApi()
  },
  methods: {
    // 获取平台配置信息
    getPlatSettingInfo() {
      this.$get(this.$apis.platSettingApi).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "platForm", {
              name: res.data["platform.mall.name"],
              contact_number: res.data["platform.mall.contact_number"],
              hot_line_time: res.data["platform.mall.hot_line_time"],
              color_matching: colorTextList.find(
                (item) =>
                  item.text == res.data["platform.mall.color_matching"].text
              ),
              show_style_type: res.data["platform.mall.show_style_type"],
              logo_url: res.data["platform.mall.logo_url"],
              company_name: res.data["platform.mall.company_name"],
              company_url: res.data["platform.mall.company_url"],
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 获取平台后台配置信息
    getBackSettingInfo() {
      this.$get(this.$apis.backSettingApi).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "paltBackInfo", {
              backend_logo_url: res.data["platform.backend.backend_logo_url"],
              background_url: res.data["platform.backend.background_url"],
              copyright_info: res.data["platform.backend.copyright_info"],
              icon_url: res.data["platform.backend.icon_url"],
              login_logo_url: res.data["platform.backend.login_logo_url"],
              prospect_url: res.data["platform.backend.prospect_url"],
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 获取平台客服配置信息
    platServiceApi() {
      this.$get(this.$apis.platServiceApi).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "serviceModel", {
              microapp_customer_logo:
                res.data["platform.mall.microapp.customer_logo"],
              microapp_customer_url:
                res.data["platform.mall.microapp.customer_url"],
              pc_customer_logo: res.data["platform.mall.pc.customer_logo"],
              pc_customer_url: res.data["platform.mall.pc.customer_url"],
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 获取平台客户端配置信息
    platClientApi() {
      this.$get(this.$apis.platClientApi).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "clientForm", {
              microapp_app_id: res.data["wechat.microapp.app_id"],
              microapp_app_secret: res.data["wechat.microapp.app_secret"],
              microapp_original_id: res.data["wechat.microapp.original_id"],
              pc_app_id: res.data["wechat.pc.app_id"],
              pc_app_secret: res.data["wechat.pc.app_secret"],
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 获取中台设置的配置信息
    middleApi() {
      this.$get(this.$apis.middleApi).then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "middleForm", {
              platform_app_id: res.data["platform.app_id"],
              platform_app_key: res.data["platform.app_key"],
              platform_app_url: res.data["platform.app_url"],
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 获取协议
    aboutApi() {
      this.$get(this.$apis.aboutApi).then((res) => {
        // console.log(this.$apis.aboutApi, res, 'resres')
        if (res.code == 200) {
          this.$nextTick(() => {
            this.$set(this, "aboutForm", {
              about_privacy: res.data["common.agreement.about_privacy"],
              about_user: res.data["common.agreement.about_user"],
              announcements: res.data["common.agreement.announcements"]
            });
          });
        } else {
          Message.error(res.message);
        }
      });
    },
    // 验证
    contarctNumValid(rules, value, callback) {
      const reg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
      if (value == "") {
        callback(new Error("请输入联系电话!"));
      }
      if (!reg.test(value)) {
        // callback(new Error("请输入正确格式的联系电话!"));
        callback();
      }
      callback();
    },
    // tabs-click
    handleClick({ name }) {
      this.$set(this, "activeName", name);
    },
    // tab切换
    typeTab(value) {
      // this.tabIndex = value;
      this.$set(this.platForm, "show_style_type", value);
    },
    // 保存
    handleSave() {
      // document.documentElement.style.setProperty('--fontColor', '#FF4200');
      if (this.activeName == "plat") {
        this.$refs["platForm"]["validate"]((valid) => {
          if (valid) {
            const newPlatInfo = {
              "platform.mall.name": this.platForm.name,
              "platform.mall.contact_number": this.platForm.contact_number,
              "platform.mall.hot_line_time": this.platForm.hot_line_time,
              "platform.mall.color_matching": this.platForm.color_matching,
              "platform.mall.show_style_type": this.platForm.show_style_type,
              "platform.mall.logo_url": this.platForm.logo_url,
              "platform.mall.company_name": this.platForm.company_name,
              "platform.mall.company_url": this.platForm.company_url,
            };
            this.$put(this.$apis.platSettingApi, newPlatInfo).then((res) => {
              if (res.code == 200) {
                //console.log(626);
                Message.success({
                  message: "编辑平台设置成功!",
                  onClose: () => {
                    window.localStorage.setItem(
                      "style",
                      this.platForm.show_style_type
                    );
                    window.location.reload();
                  },
                });
              } else {
                Message.error(res.message);
              }
            });
          }
        });
      } else if (this.activeName == "back") {
        const newBackInfo = {
          "platform.backend.backend_logo_url":
            this.paltBackInfo.backend_logo_url,
          "platform.backend.background_url": this.paltBackInfo.background_url,
          "platform.backend.copyright_info": this.paltBackInfo.copyright_info,
          "platform.backend.icon_url": this.paltBackInfo.icon_url,
          "platform.backend.login_logo_url": this.paltBackInfo.login_logo_url,
          "platform.backend.prospect_url": this.paltBackInfo.prospect_url,
        };
        this.$put(this.$apis.backSettingApi, newBackInfo).then((res) => {
          if (res.code == 200) {
            Message.success("编辑平台后台配置信息成功!");
          } else {
            Message.error(res.message);
          }
        });
      } else if (this.activeName == "service") {
        const serviceInfo = {
          "platform.mall.microapp.customer_logo":
            this.serviceModel.microapp_customer_logo,
          "platform.mall.microapp.customer_url":
            this.serviceModel.microapp_customer_url,
          "platform.mall.pc.customer_logo": this.serviceModel.pc_customer_logo,
          "platform.mall.pc.customer_url": this.serviceModel.pc_customer_url,
        };
        this.$put(this.$apis.platServiceApi, serviceInfo).then((res) => {
          if (res.code == 200) {
            Message.success("编辑平台客服配置信息成功!");
          } else {
            Message.error(res.message);
          }
        });
      } else if (this.activeName == 'client') {
        const clientInfo = {
          "wechat.pc.app_id": this.clientForm.pc_app_id,
          "wechat.pc.app_secret": this.clientForm.pc_app_secret,
          "wechat.microapp.app_id": this.clientForm.microapp_app_id,
          "wechat.microapp.app_secret": this.clientForm.microapp_app_secret,
          "wechat.microapp.original_id": this.clientForm.microapp_original_id,
        };
        this.$put(this.$apis.platClientApi, clientInfo).then((res) => {
          if (res.code == 200) {
            Message.success("编辑平台客户端配置信息成功!");
          } else {
            Message.error(res.message);
          }
        });
      } else if (this.activeName == 'middleground') {
        const middleInfo = {
          "platform.app_id": this.middleForm.platform_app_id,
          "platform.app_key": this.middleForm.platform_app_key,
          "platform.app_url": this.middleForm.platform_app_url,
        };
        this.$put(this.$apis.middleApi, middleInfo).then((res) => {
          if (res.code == 200) {
            Message.success("编辑中台配置信息成功!");
          } else {
            Message.error(res.message);
          }
        });
      } else if (this.activeName == 'about') {
        const aboutInfo = {
          'common.agreement.about_privacy': this.aboutForm.about_privacy,
          'common.agreement.about_user': this.aboutForm.about_user,
          'common.agreement.announcements': this.aboutForm.announcements
        }
        this.$put(this.$apis.aboutApi, aboutInfo).then((res) => {
          if (res.code == 200) {
            Message.success("编辑协议设置成功!");
          } else {
            Message.error(res.message);
          }
        });
      }
    },
    // 选择图片事件
    imgbrand(val) {
      if (this.setSoure == 'editor') {
        this.aboutForm[this.imageType] += `<p><img src="${val[0].file_url}" style="max-width:100%;"/></p>`;
      } else {
        this.$set(this["" + this.setSoure], this.imageType, val[0].file_url);
      }
    },
    // 点击图片
    handleImageClick(type, soure) {
      this.setSoure = soure;
      this.$set(this, "imageType", type);
      this.$refs.imglist.photoVisible = true;
    },
    // 富文本上传图片
    uploadImgs(type, soure) {
      this.setSoure = soure;
      this.$set(this, "imageType", type);
      this.$refs.imglist.photoVisible = true;
    },
    hideMore(e) {
      e.target.style = "display:none";
    },
  },
};
</script>

<style scoped lang="less">
.paltform-setting {
  width: 100%;

  & .container {
    width: 100%;
    // app-main padding:70px 20px 20px 20px
    // min-height: calc(100vh - 90px - 80px);
    // min-height: calc(100vh - 90px);
    // min-height: 100vh;
    // background: #FFFFFF;
    border-radius: 0px 0px 3px 3px;

    & .tabs {
      width: 100%;
      // min-height: 44px;
      min-height: calc(100vh - 114px);
      // background: #FFFFFF;
      border-radius: 3px 3px 0px 0px;
      margin-bottom: 60px;

      & /deep/.el-tabs__header {
        background-color: #fff;
        border-radius: 4px;

        & .el-tabs__nav-wrap::after {
          background-color: transparent !important;
        }

        & .el-tabs__item {
          // &:hover {
          //   color: #000d9a;
          // }

          // &.is-active {
          //   color: var(--fontColor) !important;
          // }

          padding: 0;
          width: 96px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          text-align: center;
        }

        // & .el-tabs__active-bar {
        //   // width: 96px !important;
        //   height: 2px;
        //   background: #000d9a;
        // }

        & .el-tabs__nav-scroll {
          height: 48px;
          padding-left: 20px;
          border-radius: 4px;

          & .el-tabs__nav {
            height: 100%;

            & .el-tabs__active-bar {
              transform: translateX(96px + 78px);
            }

            & .el-tabs__item {
              // width: 180px;
              width: auto;
              height: 100%;
              // font-size: 18px;
              // font-weight: 700;
              line-height: 48px;
              padding-right: 78px;
              text-align: left;
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              // color: #333333;
            }
          }
        }
      }

      & /deep/.el-tabs__content {
        width: 100%;
        background-color: #ffffff;
        border-radius: 4px;
      }

      & .form {
        // width: 50%;
        padding: 20px;
        box-sizing: border-box;
        clear: both;
        border-radius: 4px;

        & /deep/.el-form-item__label {
          // width: 70px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        // & /deep/.el-input {
        //   width: 356px;
        // }

        // & /deep/.el-input__inner {
        //   height: 40px;
        //   background: #ffffff;
        //   border-radius: 4px;
        //   border: 1px solid #dddfe6;
        // }

        & /deep/.el-date-editor {
          // width: 356px !important;

          & .el-range-separator {
            width: 25px !important;
          }
        }

        & .tab-content {
          & .tab_wrap {
            & .active {
              border: 1px solid #111eba !important;
            }

            & .tab-item {
              display: inline-block;
              width: 200px;
              height: 140px;
              background: #eff0ff;
              border-radius: 3px;
              padding: 0;
              border: 1px solid transparent;

              &:nth-of-type(1) {
                background-image: url("~@/assets/images/temp-1.svg");
              }

              &:nth-of-type(2) {
                background-image: url("~@/assets/images/temp-2.svg");
                margin-left: 24px;
              }
            }
          }
        }

        & .template {
          & /deep/.el-radio-group {
            display: flex;
            justify-content: flex-start;

            & .el-radio {
              padding: 0;
              width: 114px;
              height: 50px;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              margin-left: 2px;

              &:nth-of-type(1) {
                & .el-radio__inner {
                  background: linear-gradient(360deg,
                      #3444ff 0%,
                      #030f9f 100%) !important;
                }
              }

              &:nth-of-type(2) {
                & .el-radio__inner {
                  background: linear-gradient(360deg,
                      #d15ee7 0%,
                      #660eff 100%) !important;
                }
              }

              &:nth-of-type(3) {
                & .el-radio__inner {
                  background: linear-gradient(360deg,
                      #fe8c90 0%,
                      #fc3b45 100%) !important;
                }
              }

              &:nth-of-type(4) {
                & .el-radio__inner {
                  background: linear-gradient(360deg,
                      #00d294 0%,
                      #009167 100%) !important;
                }
              }

              &:nth-of-type(5) {
                & .el-radio__inner {
                  background: #28272d !important;
                }
              }

              &:nth-of-type(6) {
                & .el-radio__inner {
                  background: #3b69ff !important;
                }
              }

              &:nth-of-type(7) {
                & .el-radio__inner {
                  background: #ff5654 !important;
                }
              }

              &.is-bordered {
                border: 1px solid transparent;
              }

              &.is-checked {
                background: #eff0ff;
                border: 1px solid #111eba;
              }

              & .el-radio__input.is-checked .el-radio__inner {
                background-color: inherit;
              }

              & .el-radio__inner {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                border: none;

                &::after {
                  width: 0;
                  height: 0;
                }
              }

              & .el-radio__label {
                padding: 0;
                margin-left: 8px;
                width: 42px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666 !important;
                line-height: 20px;
              }
            }
          }
        }
      }

      & .backstage {
        padding: 20px 20px 0;
        background-color: #fff;

        & .logo-setting {
          width: 100%;
          height: 230px;
          margin-bottom: 56px;

          & .images {
            height: 100%;
            // background: #F4F6F9;
            border-radius: 5px;
            display: flex;

            & .upload {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-left: 40px;

              & /deep/.el-form-item {
                display: flex;
                align-items: center;
                margin: 0;

                &:nth-of-type(1) {
                  &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    content: "01";
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: #aaaaaa;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;
                  }
                }

                &:nth-of-type(2) {
                  &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    content: "02";
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: #aaaaaa;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;
                  }
                }

                &:nth-of-type(1) {
                  margin-top: 3px;
                }

                &:nth-of-type(2) {
                  margin-top: 42px;
                }

                & .el-form-item__label {
                  display: flex;
                  align-items: center;
                  width: 85px;
                  // height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  padding: 0;

                  // line-height: 20px;
                }

                & .addressright {
                  width: 78px;
                  height: 78px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px dashed #ededed;
                  cursor: pointer;

                  & .addresstx {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &>span {
                      display: inline-block;
                      width: 200px;
                      height: 20px;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #999999;
                      line-height: 20px;
                      position: absolute;
                      bottom: -28px;
                      left: 0;
                    }

                    &>i {
                      font-size: 24px;
                      color: #dad9d9;
                    }
                  }
                }
              }
            }

            &>img {
              width: 467px;
              height: 223px;
              object-fit: contain;
              flex-shrink: 0;
            }
          }
        }

        & .login-info {
          width: 100%;
          height: 290px;
          margin-bottom: 56px;

          & .images {
            height: 100%;
            // background: #F4F6F9;
            border-radius: 5px;
            display: flex;

            & .upload {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-left: 40px;

              & /deep/.el-form-item {
                display: flex;
                align-items: center;
                margin: 0;

                &:nth-of-type(1) {
                  &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    content: "01";
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: #aaaaaa;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;
                  }
                }

                &:nth-of-type(2) {
                  &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    content: "02";
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: #aaaaaa;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;
                  }
                }

                &:nth-of-type(3) {
                  &::before {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    content: "03";
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: #aaaaaa;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 8px;
                  }
                }

                &:nth-of-type(1) {
                  margin-top: 3px;
                }

                &:nth-of-type(2) {
                  margin-top: 42px;
                }

                &:nth-of-type(3) {
                  margin-top: 42px;

                  & .el-input {
                    // width: 356px;
                    height: 40px;
                    background: #ffffff;
                    border-radius: 4px;
                  }
                }

                & .el-form-item__label {
                  display: flex;
                  align-items: center;
                  width: 85px;
                  // height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  padding: 0;

                  // line-height: 20px;
                }

                & .addressright {
                  width: 78px;
                  height: 78px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px dashed #ededed;
                  cursor: pointer;

                  & .addresstx {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &>span {
                      display: inline-block;
                      width: 200px;
                      height: 20px;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #999999;
                      line-height: 20px;
                      position: absolute;
                      bottom: -28px;
                      left: 0;
                    }

                    &>i {
                      font-size: 24px;
                      color: #dad9d9;
                    }
                  }
                }
              }
            }

            &>img {
              width: 467px;
              height: 270px;
              object-fit: contain;
              flex-shrink: 0;
            }
          }
        }

        & .login-setting {
          width: 100%;
          height: 300px;
          // margin-bottom: 56px;

          & .images {
            height: 100%;
            // background: #F4F6F9;
            border-radius: 5px;
            display: flex;

            & .upload {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 40px;
              margin-top: -25px;

              & /deep/.el-form-item {
                display: flex;
                align-items: center;
                margin: 0;

                & .el-form-item__label {
                  display: flex;
                  align-items: center;
                  width: 85px;
                  // height: 20px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  padding: 0;
                  margin-left: 30px;

                  // line-height: 20px;
                }

                & .addressright {
                  width: 78px;
                  height: 78px;
                  background: #ffffff;
                  border-radius: 2px;
                  border: 1px dashed #ededed;

                  cursor: pointer;

                  & .addresstx {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &>span {
                      display: inline-block;
                      width: 200px;
                      height: 20px;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #999999;
                      line-height: 20px;
                      position: absolute;
                      bottom: -28px;
                      left: 0;
                    }

                    &>i {
                      font-size: 24px;
                      color: #dad9d9;
                    }
                  }
                }
              }
            }

            &>img {
              width: 467px;
              height: 270px;
              object-fit: contain;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }

  & .class_foot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    box-sizing: border-box;

    & .attr_foot_cancel {
      border: 1px solid transparent;
      color: #666;
      background-color: #fff;
      border-color: #ededed;
      margin-right: 20px;
    }
  }
}

.addressright-logo {
  >span {
    display: inline-block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .addresstx {
    width: 160px;
    height: 80px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px dashed #ededed;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
    }
  }
}

.addressright {
  width: 78px;
  height: 78px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px dashed #ededed;
  cursor: pointer;

  & .addresstx {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &>span {
      display: inline-block;
      width: 200px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      position: absolute;
      bottom: -28px;
      left: 0;
    }

    &>i {
      font-size: 24px;
      color: #dad9d9;
    }
  }
}

.customer_title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 40px;
}

.more_input {
  position: relative;
  width: 378px;

  .more_hide {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #636c72;
    z-index: 3;
    line-height: 2;
  }

  /deep/.el-input {
    width: 100%;
  }
}

.wechat_input {
  position: relative;
  width: 275px;

  .more_hide {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    color: #636c72;
    z-index: 3;
    line-height: 2;
  }

  /deep/.el-input {
    width: 100%;
  }
}
</style>